import { css } from '@emotion/react'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import contactBackground from '../assets/img/pages/contact/background.jpg'
import ReviewSVG from '../assets/svg/review.svg'
import ButtonLink from '../components/elements/buttons/ButtonLink'
import Card from '../components/elements/cards/Card'
import PaymentInformationCard from '../components/elements/cards/PaymentInformationCard'
import CustomerDataForm from '../components/forms/CustomerDataForm'
import BaseLayout from '../components/layout/BaseLayout'
import DashHeading from '../components/layout/DashHeading'
import Section from '../components/layout/Section'
import makeSeoTitle from '../lib/utils/makeSeoTitle'

const ContactPage = () => (
  <BaseLayout>
    <Section
      backgroundImage={contactBackground}
      css={css`
        background-repeat: no-repeat;
        background-size: cover;
      `}
    >
      <Container
        css={css`
          padding: 0;
        `}
      >
        <Row>
          <Col md={12} lg={7}>
            <Card dark>
              <h1
                css={css`
                  font-size: 1.8rem;
                  margin-bottom: 0.5rem;
                `}
              >
                Kundendaten
              </h1>
              <p
                css={css`
                  margin-bottom: 2.5rem;
                `}
              >
                Bitte geben Sie hier Ihre persönlichen Daten zur
                Rechnungserstellung ein.
              </p>
              <CustomerDataForm />
            </Card>
          </Col>
          <Col md={12} lg={5}>
            <PaymentInformationCard style={{ height: 'unset' }} />
            <Card dark style={{ height: 'unset' }}>
              <ReviewSVG style={{ height: 100 }} />

              <DashHeading>Review</DashHeading>
              <p>
                Zufrieden mit meiner Arbeit? Hinterlassen Sie doch gerne ein
                Review auf Google, um auch andere davon wissen zu lassen!
              </p>

              <ButtonLink
                variant="primary"
                block
                outlined
                href="https://g.page/EisDesigns/review?rc"
                target="_blank"
                rel="noopener noreferrer"
                external
              >
                Review auf Google schreiben
              </ButtonLink>
            </Card>
          </Col>
        </Row>
      </Container>
    </Section>
  </BaseLayout>
)

export const Head = () => (
  <>
    <title>{makeSeoTitle('Kundendaten')}</title>
    <meta name="robots" content="noindex, nofollow" />
  </>
)

export default ContactPage
