import * as yup from 'yup'

import React, { useState } from 'react'

import Alert from '../elements/Alert'
import Button from '../elements/buttons/Button'
import CheckboxField from '../elements/form/CheckboxField'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ExternalLink from '../elements/links/ExternalLink'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import SelectField from '../elements/form/SelectField'
import TextField from '../elements/form/TextField'
import apiClient from '../../lib/apiClient'
import { css } from '@emotion/react'
import validationMessages from '../../messages/validation'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(validationMessages.validEmail)
    .required(validationMessages.required),
  firstName: yup
    .string()
    .required(validationMessages.required)
    .max(100, validationMessages.max(100)),
  lastName: yup
    .string()
    .required(validationMessages.required)
    .max(100, validationMessages.max(100)),
  company: yup.string(),
  street: yup
    .string()
    .required(validationMessages.required)
    .max(100, validationMessages.max(100)),
  zipCode: yup
    .string()
    .required(validationMessages.required)
    .max(10, validationMessages.max(10)),
  city: yup
    .string()
    .required(validationMessages.required)
    .max(100, validationMessages.max(100)),
  country: yup.string().oneOf(['de', 'au', 'ch']),
  gdpr: yup.bool().isTrue(),
})

const CustomerDataForm = () => {
  const [submitStatus, setSubmitStatus] = useState(null)

  const submitHandler = (values, { setSubmitting, resetForm }) => {
    apiClient
      .post('/customer_data', values)
      .then(() => {
        setSubmitStatus('success')
        resetForm()
      })
      .catch(() => {
        setSubmitStatus('error')
      })
      .finally(() => {
        setSubmitting(false)
      })

    setTimeout(() => {
      setSubmitStatus(null)
    }, 10000)
  }

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        street: '',
        zipCode: '',
        city: '',
        country: 'de',
        gdpr: false,
      }}
      isInitialValid={false}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <Alert visible={submitStatus === 'success'}>
            Deine Daten wurden erfolgreich übermittelt.
          </Alert>
          <Alert variant="error" visible={submitStatus === 'error'}>
            Deine Daten konnten nicht übermittelt werden.
            <br />
            Bitte versuche es später erneut.
          </Alert>
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={12}>
                <Form.Label>E-Mail-Adresse</Form.Label>
                <TextField name="email" placeholder="max@mustermann.de" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={6}>
                <Form.Label>Vorname</Form.Label>
                <TextField name="firstName" placeholder="Max" />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Nachname</Form.Label>
                <TextField name="lastName" placeholder="Mustermann" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={12}>
                <Form.Label>Firma (optional)</Form.Label>
                <TextField name="company" placeholder="Mustermann GmbH" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={12}>
                <Form.Label>Straße</Form.Label>
                <TextField name="street" placeholder="Musterstraße 1" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={4}>
                <Form.Label>PLZ</Form.Label>
                <TextField name="zipCode" placeholder="PLZ" />
              </Form.Group>
              <Form.Group as={Col} md={8}>
                <Form.Label>Ort</Form.Label>
                <TextField name="city" placeholder="Musterstadt" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={12}>
                <Form.Label>Land</Form.Label>
                <SelectField
                  name="country"
                  isSearchable={false}
                  options={[
                    { label: 'Deutschland', value: 'de' },
                    { label: 'Österreich', value: 'au' },
                    { label: 'Schweiz', value: 'ch' },
                  ]}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={12}>
                <CheckboxField
                  name="gdpr"
                  label={
                    <p
                      css={css`
                        font-size: 12px;
                      `}
                    >
                      Ich habe die{' '}
                      <ExternalLink to="/datenschutzerklaerung/">
                        Datenschutzerklärung
                      </ExternalLink>{' '}
                      zur Kenntnis genommen. Ich stimme zu, dass meine Angaben
                      und Daten zur Rechnungsstellung elektronisch erhoben und
                      gespeichert werden.
                    </p>
                  }
                />
              </Form.Group>
            </Row>

            <Row>
              <Col md={12}>
                <Button
                  type="submit"
                  variant="primary"
                  block
                  loading={isSubmitting}
                >
                  Absenden
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Formik>
  )
}
export default CustomerDataForm
