import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../theme'
import { useField } from 'formik'

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  p,
  a {
    color: ${props => (props.isInvalid ? '#dc3545' : '#ffffff')} !important;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input ~ .checkmark {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  input:checked ~ .checkmark {
    background-color: ${theme.palette.blue};
  }

  input:checked ~ .checkmark::after {
    display: block;
  }

  .checkmark::after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  border-radius: 0.25rem;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }
`

const CheckboxField = ({ name, label }) => {
  const [field, meta, helpers] = useField(name)

  const handleClick = evt => {
    helpers.setValue(evt.target.checked)
  }

  return (
    <Container isInvalid={meta.touched && meta.error}>
      <input
        type="checkbox"
        name={field.name}
        checked={field.value}
        onChange={handleClick}
      />
      <Checkmark className="checkmark" />
      {label}
    </Container>
  )
}

export default CheckboxField
